<template>
  <div class="net pl-9 pr-9 header-clear">
    <v-row class="pb-9 wrapper">
      <h2 class="mt-12">Интернет</h2>
    </v-row>
    <v-row class="wrapper pb-12">
        <v-card
          class="mx-auto text-center mb-12 card card-net is-visible"
          max-width="344"
          width="100%"
          color="#fff"
          v-for="card in cards"
          :key="card.id"
        >
          <v-card-text>
            <h4 class="card-title about-card-title mt-3">{{ card.title }}</h4>
            <p class="display-3 black--text mb-0 mt-4">
              {{ card.internet }}
            </p>
            <p class="black--text">{{ card.sub }}</p>
            <ul class="ul-text pa-0 mb-4 black--text">
              <li><v-icon
                right
                class="ml-0 mr-1"
              >
              mdi-check
            </v-icon>Ниски цени</li>
              <li><v-icon
                  right
                  class="ml-0 mr-1"
                >
                mdi-check
                </v-icon>Висока скорост</li>
              <li><v-icon
                  right
                  class="ml-0 mr-1"
                >
                mdi-check
              </v-icon>Надеждна връзка</li>
            </ul>
            <div class="black--text">
              <div>Цена</div>
              <span class="display-1 black--text">{{ card.price }}</span>
            </div>
          </v-card-text>
          <v-btn
              outlined
              color="#000"
              class="text-center mb-6"
              v-model="about" 
              :id="about.title"
              :to="about.to"
              @click="about.to"
            >
              Научи повече
            </v-btn>
        </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
    data: () => ({
        cards: [
            { id: '1', title: 'Стандарт - Нет', internet: '50', sub: 'Mbit/s', price: '20,00лв' },
            { id: '2', title: 'Разширен - Нет', internet: '80', sub: 'Mbit/s', price: '30,00лв' },
            { id: '3', title: 'Разширен Плюс - Нет', internet: '100', sub: 'Mbit/s', price: '40,00лв' },
        ],
        about: {
            title: 'Services',
            to: '/services'
        },
    })
}
</script>

<style>

</style>